import { t } from "@/@core/libs/i18n/utils";
import { amgApi } from "@/service/axios";

class ClientServiceBoostCredit {
  async getBCClients(params) {
    const data = await amgApi.post(
      `${params.api_url}?page=${params.current_page}`,
      params
    );
    return data.data;
  }

  async saveCallRound(params) {
    const data = await amgApi.post("/commons/save-call-round", params);
    return data.data;
  }
  async searchClients(params) {
    const { data } = await amgApi.post(
      "/clients/search-clients-boostcredit",
      params
    );
    return data;
  }

  async counterClients(params) {
    try {
      const data = await amgApi.post(
        "/clients/counter-clients-boost-credit",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ClientServiceBoostCredit();
