import { amgApi } from "@/service/axios";

class TrackingListService {

    async searchTrackingList(params) {
        const data = await amgApi.post('/commons/boost-credit/search-tracking-list', params)
        return data;
    }

    async updateStatusTracking(params, isConnection = false) {
        let data
        if (isConnection) {
            data = await amgApi.post('/credit-expert/connection/update-status-tracking', params)
        } else {
            data = await amgApi.post('/commons/boost-credit/update-status-tracking', params)
        }
        return data;
    }

    async finishTrackingList(params, isConnection = false) {
        let data
        if (isConnection) {
            data = await amgApi.post('/credit-expert/connection/finish-tracking-list', params)
        } else {
            data = await amgApi.post('/commons/boost-credit/finish-tracking-list', params)
        }
        return data;
    }

    async userModule(params, modul) {
        const data = await amgApi.post('/commons/user-module/' + modul, params)
        return data;
    }


}

export default new TrackingListService();