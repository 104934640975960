<template>
  <div>
    <b-modal
      modal
      :title="typeNameReport == 1 ? 'SEND STATEMENT' : 'STATUS REPORT'"
      v-model="openModalStatus"
      size="lg"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      @hidden="closeModalStatus"
      title-tag="h3"
      header-class="p-0"
      :no-close-on-backdrop="true"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{ typeNameReport == 1? 'Send Statement' : 'Status Report' }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModalStatus"
            />
          </div>
        </div>
      </template>
      <ValidationObserver ref="form">
        <!-- Titles -->
        <b-row class="mt-1 pb-1">
          <b-col cols="12" md="6" lg="6">
            <!-- <span class="title-tag">CLIENT NAME</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client | {{ client.client_name }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
            <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client Account | {{ client.account }}
              </p>
            </div>
          </b-col>
        </b-row>
        <!-- STEP 1 -->
        <b-row class="mb-1">
          <b-col cols="12">
            <label class="text-primary" style="font-size: 15px" for="step"
              >STEP 1</label
            >
          </b-col>
        </b-row>
        <b-row class="mb-1 ml-1">
          <b-col cols="6">
            <h5 class="text-left">Select Type Send:</h5>
            <div>
              <b-form-radio-group
                class="text-danger"
                v-model="letter"
                :options="options"
                @change="changeGenerate"
                name="radios-stacked"
              />
            </div>
          </b-col>
        </b-row>
        <!-- STEP 2 -->
        <b-row class="mb-1">
          <b-col cols="12">
            <label class="text-primary" style="font-size: 15px" for="step"
              >STEP 2</label
            >
          </b-col>
        </b-row>
        <b-row class="mb-1 ml-1">
          <b-col cols="8" class="d-flex align-items-center">
            <b-button :disabled="btnGenerate" @click="savePdf" variant="success"
              >Generate Statement</b-button
            >
            <a
              v-if="url_pdf != ''"
              class="ml-1"
              target="_blank"
              :href="url_pdf"
            >
              <img
                width="20"
                height="20"
                :src="assetsImg + '/images/icons/pdf.png'"
              />
              <span>{{ name_pdf }}</span>
            </a>
          </b-col>
        </b-row>
        <!-- STEP 3 -->
        <b-row class="mb-1">
          <b-col cols="12">
            <label class="text-primary" style="font-size: 15px" for="step"
              >STEP 3</label
            >
          </b-col>
        </b-row>
        <b-row class="mb-1 ml-1 mr-1">
          <b-col cols="12">
            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Observation :">
                <b-form-textarea
                  id="message"
                  name="message"
                  v-model="observation"
                  rows="2"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  maxlength="1000"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-end align-items-center">
          <b-form-checkbox v-model="savenote" name="check-button" switch>
            <span>Save as Note</span>
          </b-form-checkbox>
          <b-button
            class="ml-1"
            :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
            variant="success"
            :disabled="btnSend"
            @click="save"
            >Send</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// Import services
import ClientDashboardService from "../../services/clients.dashboard.services";
export default {
  props: {
    modalStatusReport: {
      type: Boolean,
    },
    titleModal: {
      type: String,
      default: "STATUS REPORT",
    },
    pdfNameSaved: {
      type: String,
      default: 'STATUS_REPORT'
    },
    clientParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      openModalStatus: this.modalStatusReport,
      tittleModalStatusReport: this.titleModal,
      pdfName: this.pdfNameSaved,
      letter: "",
      observation: "",
      options: [
        {
          value: "1",
          text: "EMAIL",
        },
        {
          value: "2",
          text: "CORRESPONDENCE",
        },
      ],
      btnGenerate: true,
      url_pdf: "",
      url_pdf_bd: "",
      name_pdf: "",
      data: [],
      btnSend: true,
      savenote: "",
      clientObj: {},
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    typeNameReport() {
      if (this.moduleId == 7 || this.moduleId == 3) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  methods: {
    changeGenerate() {
      if (this.letter == 1 || this.letter == 2) {
        this.btnGenerate = false;
      }
    },

    savePdf: async function () {
      try {
        this.name_pdf = "";
        this.url_pdf = "";
        let params = {
          accountid: this.$route.params.idClient || this.clientParam.client_account_id,
          typepdf: this.letter == 1 ? 1 : 2,
          leadid: this.clientObj.lead_id,
        };
        this.addPreloader();
        const response = await ClientDashboardService.savePdfStatement(params);
        this.removePreloader();
        if (response.status == 200) {
          this.data = response.data;
          this.url_pdf = this.data[0];
          this.name_pdf =
            this.moduleId == 7 ? "OPEN_STATEMENT" : "STATUS_REPORT";
          if (this.name_pdf != "") {
            this.btnSend = false;
          } else {
            this.btnSend = true;
          }

          this.url_pdf_bd = this.data[1];
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with PDF!"
        );
      }
    },
    save: async function () {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          return;
        } else {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            try {
              let params = {
                statement: this.letter,
                observation: this.observation,
                note: this.savenote == true ? 1 : 0,
                iduser: this.currentUser.user_id,
                accountid: this.$route.params.idClient || this.clientParam.client_account_id,
                urlpdf: this.url_pdf_bd,
                statuspdf: this.letter == 1 ? 3 : 1,
                urlfull: this.url_pdf,
                n_type: 3,
              };
              this.addPreloader();
              const response = await ClientDashboardService.sendEmailStatus(
                params
              );
              this.removePreloader();
              this.closeModalStatus();
              this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
            } catch (error) {
              this.removePreloader();
              console.log(error);
              this.showToast(
                "danger",
                "top-right",
                "Error",
                "XIcon",
                "Something went wrong with Send Status!"
              );
            }
          }
        }
      });
    },
    closeModalStatus() {
      this.$emit("closeModalStatus");
    },
  },
  created(){
    if(Object.keys(this.client).length > 0){
      this.clientObj = this.client;
    }else{
      this.clientObj = this.clientParam;
    }
    if(this.moduleId == 7 || this.moduleId == 3){
      this.letter = 2;
      this.options.splice(0, 1);
      this.changeGenerate();
    }
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
</style>
