<template>
  <b-row class="py-1s">
    <b-col>
      <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
        <p
          class="m-0"
          style="gap: 5px"
        >
          Client | {{ client.client_name }}
        </p>
      </div>
    </b-col>
    <b-col>
      <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
        <p
          class="m-0"
          style="gap: 5px"
        >
          Account | {{ client.account }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProgramClientHeader',
  props: {

    client: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
