<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />
          <!-- Share -->
          <b-button
            v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
            :disabled="isResponsibleAccount"
            @click="openModalShare"
          >
            <feather-icon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->
          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            @click="openModalProgramList"
          >
            <feather-icon icon="MonitorIcon" />
            Others Programs
          </b-button>
          <b-button-group variant="success" class="ml-1">
            <b-button
              :disabled="isResponsibleAccount"
              class="custom-button"
              @click="openModalSendSms"
            >
              Send SMS
            </b-button>
            <b-button class="custom-button" @click="openModalHistorySms">
              <feather-icon icon="ListIcon" class="text-white" />
            </b-button>
          </b-button-group>
        </div>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row>
          <b-col lg="auto">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <b-form-group style="margin-bottom: 5px !important">
              <template v-if="validateAccount">
                <span
                  v-if="!isResponsibleAccount"
                  style="font-weight: bolder; font-size: 17.5px"
                  class="cursor-pointer"
                  @click="openEditLead"
                  >{{ client.client_name }}</span
                >
                <span v-else style="font-weight: bolder; font-size: 17.5px"
                  >{{ client.client_name }}
                </span>
                <span
                  v-if="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible
                  "
                  class="text-primary"
                >
                  <tabler-icon
                    :icon="
                      $store.getters[
                        'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                      ].is_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                    style="margin-left: 5px"
                  />
                  <strong>CEO's client</strong>
                </span>
                <br />
              </template>
            </b-form-group>
            <b-form-group>
              <template v-if="validateAccount">
                <span style="font-size: 14.5px"
                  ><span
                    style="
                      font-weight: bolder;
                      font-size: 14px;
                      margin-right: 5px;
                    "
                    >Account:</span
                  >
                  {{ client.account }}</span
                >
                <br />

                <StatusClientAccount />
              </template>
            </b-form-group>
          </b-col>
          <b-col v-if="screenWidth > 1400" lg="3" />
          <b-col v-if="client.track_finish == null ? false : true" lg="1" />

          <b-col lg="2" />
          <b-col
            v-if="client.track_id != null"
            style="margin: 0px; padding: 0px"
            lg="auto"
          >
            <span
              >TRACKING: {{ client.tracking_start }} -
              {{ client.tracking_end }}</span
            >
            <b-row>
              <b-col lg="auto">
                <b-input-group prepend="FILE">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <feather-icon
                        v-if="client.file_status == null"
                        class="text-secondary cursor-pointer"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 1)"
                      />
                      <feather-icon
                        v-else-if="client.file_status != null"
                        v-b-tooltip.hover.left="client.file_status"
                        class="cursor-pointer text-success"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 2)"
                      />
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="CALL">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="danger"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              1,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-badge>
                        <b-badge
                          :id="'call-target-' + client.track_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.call_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_calls)
                              ? JSON.parse(client.dates_calls).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'call-target-' + client.track_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(
                                client.dates_calls
                              )"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="success"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              2,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="SMS">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="danger"
                          class="cursor-pointer"
                        >
                          <feather-icon
                            icon="MinusIcon"
                            @click="
                              checkstatus(
                                client.track_id,
                                3,
                                1,
                                client.dates_sms
                              )
                            "
                          />
                        </b-badge>
                        <b-badge
                          :id="'sms-target-' + client.tracking_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.sms_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_sms)
                              ? JSON.parse(client.dates_sms).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'sms-target-' + client.tracking_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(client.dates_sms)"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="success"
                          class="cursor-pointer"
                          @click="
                            checkstatus(client.track_id, 3, 2, client.dates_sms)
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto" class="d-flex align-items-center">
                <b-button
                  v-if="client.track_finish == null ? true : false"
                  :disabled="
                    !(
                      client.call_status > 0 &&
                      client.file_status != null &&
                      client.sms_status > 0
                    )
                  "
                  size="sm"
                  variant="primary"
                  @click="finishTracking(client.track_id)"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CheckCircleIcon"
                  />FINISH</b-button
                ></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'personal-information-bookeeping-general' }
              : { name: 'personal-information-bookeeping' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'pay-bookeeping-general' }
              : { name: 'pay-bookeeping' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-files-bookeeping-general' }
              : { name: 'client-files-bookeeping' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'acr-bookeeping-general' }
              : { name: 'acr-bookeeping' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'call-log-bookeeping-client-general' }
              : { name: 'call-log-bookeeping-client' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          :active="isCallLog"
          >Call Log</b-nav-item
        >
        <b-nav-item
          v-if="validationArrRoles"
          v-b-tooltip.hover.bottomright="'Customer Tickets'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-bookeeping-tickets-general' }
              : { name: 'client-bookeeping-tickets' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
          >Customer Tickets
          <span
            v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                  ? '99+'
                  : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
              "
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view />
      </b-card>
    </b-card>

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <!-- Send CR -->
    <modal-create-ncr
      v-if="modalCreateNcr"
      :modal-create-ncr="modalCreateNcr"
      :typencr="1"
      @closeModalCreateNcr="closeModalCreateNcr"
    />

    <modal-create-ncr-realtor
      v-if="modalCreateNcrRealtor"
      :typencr="1"
      @closeModalCreateNcrRealtor="closeModalCreateNcrRealtor"
    />
    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />

    <modal-status-report
      v-if="modalStatusReport"
      :modal-status-report="modalStatusReport"
      @closeModalStatus="closeModalStatus"
    />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <send-to-call-rounds
      v-if="modalCallRound"
      :modal-call-round="modalCallRound"
      :name-leads="clientsSms"
      :type-call="0"
      @validateStatus="validateStatus"
      @closeModal="closeModalCallRound"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

// Import Modals

import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList";
import ModalCreateNcr from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcr";
import ModalCreateNcrRealtor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcrRealtor";
import ModalStatusReport from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail";
import SendToCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds";
import moment from "moment";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";

import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";

export default {
  components: {
    SendToCallRounds,
    ModalShare,
    ModalProgramList,
    ModalCreateNcr,
    ModalCreateNcrRealtor,
    ModalSendSms,
    ModalSendEmail,
    ModalStatusReport,
    LeadUpdate,
    ModalHistory,
    CourtInfoAction,
    StatusClientAccount,
    ModalHistorySms,
  },

  data() {
    return {
      cont: 0,
      validateStatusCall: false,
      clientsSms: [],
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalStatusReport: false,
      modalCallRound: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      activeModalHistorySms: false,
    };
  },
  computed: {
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },

    validationArrRoles() {
      if (
        this.currentUser.arrRoles.find(
          (rol) =>
            (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
            rol.module_id !== 24 &&
            rol.user_id !== 38
        )
      ) {
        return true;
      }
      return false;
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    try {
      this.addPreloader();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
      ]);
      // await this.getCountFiles()
      // await this.getCounterDispute()
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    if (this.$route.matched[0].meta.general) {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    }
  },
  async mounted() {
    await this.$store.dispatch(
      "NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO",
      { account_id: this.$route.params.idClient }
    );
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await trackingListService.finishTrackingList({
            id,
          });
          if (data.status === 200) {
            this.cont++;
            this.client.track_finish = data.data[0].track_finish;

            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async checkstatus(id, type, status, datos) {
      try {
        if (this.client.track_finish == null) {
          const date = moment().format("MM/DD/YYYY hh:mm A");
          const array = datos == null ? [] : JSON.parse(datos);
          if (status === 2) {
            array.push(date);
          } else {
            array.pop();
          }

          const data = await trackingListService.updateStatusTracking({
            id,
            type,
            status,
            newval: array,
          });
          if (data.status === 200) {
            this.client.file_status = data.data[0].file_status;
            this.client.call_status = data.data[0].call_status;
            this.client.dates_calls = data.data[0].dates_calls;
            this.client.sms_status = data.data[0].sms_status;
            this.client.dates_sms = data.data[0].dates_sms;
          }
        }
      } catch (e) {}
    },
    validateStatus() {
      this.validateStatusCall = true;
    },
    async refresh() {
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },

    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
    }),
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      // await all promises
      this.addPreloader();
      await this.getLeadEdit();

      if (this.editSidebar === "first") {
        await Promise.all([
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
      }
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },

    openModalCreateNcr() {
      this.modalCreateNcr = true;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },

    async openModalCallRound(status) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id: this.client.id,
            name: this.client.client_name,
            account: this.client.accounts,
            nameAccount: this.client.account,
            nameProgram: this.client.program_name,
          },
        ];
        this.modalCallRound = true;
      }
    },
    closeModalCallRound() {
      this.modalCallRound = false;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalStatusReport() {
      this.modalStatusReport = true;
    },
    closeModalStatus() {
      this.modalStatusReport = false;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
  },
};
</script>

<style>
.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.content-header .content-header-right .court-info button {
  border-color: #82868b !important;
  background-color: #82868b !important;
}
.content-header .content-header-right .court-info-court button {
  border-color: #8f5fe8 !important;
  background-color: #8f5fe8 !important;
}
</style>
