<template>
  <b-modal
    v-model="isActive"
    @hidden="closeMe"
    title="History Of SMS"
    title-tag="h3"
    size="lg"
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    hide-footer
    scrollable
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          History Of SMS
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeMe"
          />
        </div>
      </div>
    </template>
    <CoolLightBox
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <account-client-header-deb :client="client" :account="account" />
    <b-table :items="history" :fields="fields" show-empty sticky-header="50vh" class="table-new-customization custom-table">
      <template #cell(user_name)="data">
        {{ data.item.user_name }} {{ data.item.created_at | myGlobalDay }}
      </template>
      <template #cell(content)="data">
        <div v-html="data.item.content"></div>
      </template>
      <template #cell(image)="data">
        <template v-if="data.item.image != null">
          <div
            class="cover-image clickable"
            @click="openImage(data.item.image)"
            :style="`background-image: url(${data.item.image})`"
          ></div>
        </template>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";

import { mapGetters } from "vuex";
import AccountClientHeaderDeb
  from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/AccountClientHeaderDeb.vue";
export default {
  created() {
    this.isActive = this.active;
  },
  props: {
    active: { type: Boolean },
  },
  components: {
    AccountClientHeaderDeb,
    CoolLightBox,
  },
  data() {
    return {
      isActive: false,
      fields: [
        { key: "user_name", label: "Send By" },
        { key: "number", label: "Number", class: "w-25" },
        { key: "content", label: "Content" },
        { key: "image", label: "Image" },
      ],
      itemImage: [],
      showImage: null,
    };
  },
  methods: {
    closeMe() {
      this.isActive = false;
      this.$emit("close");
    },
    openImage(image) {
      this.itemImage.push(image);
      this.showImage = 0;
    },
  },
  computed: {
    ...mapGetters({
      account: "ParagonClientDashboard/getAccount",
      client: "DebtSolutionClients/G_CLIENTS",
      history: "ParagonClientDashboard/getHistorySms",
    }),
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.cover-image {
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin-top: 50%;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>